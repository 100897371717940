import './Offtake.css'
import React, { useState } from 'react'

import ViewPanel from '../../ViewPanel'
import { 
    PalantirSelector,
    PalantirDatePicker,
    PalantirBooleanSelector
} from '../../../../components/input/SelectPicker'
import ContractIdentification from '../ContractIdentification'
import ContractHeader from '../ContractHeader'
import ContractCounterparties from '../ContractCounterparties'
import { useDBViewFormValidation } from '../../../../hooks/databaseViewFormValidation'
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from '../../../../components/input/Text'
import { OfftakeCounterpartiesContract, OfftakeSCCounterpartiesContract, OfftakeContract, OfftakeCreditRating } from '../../../table_configuration/Contract'
import { Offtake, SchedulingCoordinator } from '../../../table_configuration/Counterparty'
import { useChangeLog, injectedChangeLogIdCol } from '../../../../hooks/changeLog'
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from '../../../../components/button/FloaterButtonWithPrompt'
import { prepareContractRecords, prepareContractCounterpartyRecords, validateCreateContractCounterparty, buildContractNameErrorConfig, buildContractDependencyErrorPath } from '../utils'
import { generateUUID } from '../../../../utils/databaseAppUtils'
import { HelpPrompt } from '../../../../components/help/HelpPrompt'
import { HelpProjectCOD } from '../../../../components/help/precious/Columns'

import _ from 'lodash'


const offtakeIdCol = OfftakeCounterpartiesContract.columnSchema.offtakeId
const schedulingCoordinatorIdCol = OfftakeSCCounterpartiesContract.columnSchema.schedulingCoordinatorId
const injectedOfftakeName = "offtakeName"
const injectedSchedulingCoordinatorName = "schedulingCoordinatorName"

const TextMaskTermYears = makeTextMaskNumber({
    min: 0
})
const TextMaskCapacityLimit = makeTextMaskNumber({
    scale: 20
})


export default function OfftakePanelProxy(props) {

    const contractRecords = props.data[OfftakeContract.buildId()]
    const contractCreditRatingRecords = props.data[OfftakeCreditRating.buildId()]
    const contractCounterpartyRecords = props.data[OfftakeCounterpartiesContract.buildId()]
    const contractSCCounterpartyRecords = props.data[OfftakeSCCounterpartiesContract.buildId()]
    const offtakeRecords = props.data[Offtake.buildId()]
    const schedulingCoordinatorRecords = props.data[SchedulingCoordinator.buildId()]

    const [filteredContractRecords, filteredContractIds] = prepareContractRecords(contractRecords, props.selectedPlantId, OfftakeContract.columnSchema.contractId, OfftakeContract.columnSchema.plantId)
    const filteredContractCreditRatingRecords = contractCreditRatingRecords.filter(x => filteredContractIds.includes(x[OfftakeCreditRating.columnSchema.contractId]))
    const filteredContractCounterparties = prepareContractCounterpartyRecords(
        filteredContractIds, contractCounterpartyRecords, offtakeRecords,
        OfftakeCounterpartiesContract.columnSchema.contractId, OfftakeCounterpartiesContract.columnSchema.offtakeId, injectedOfftakeName
    )
    const filteredContractSCCounterparties = prepareContractCounterpartyRecords(
        filteredContractIds, contractSCCounterpartyRecords, schedulingCoordinatorRecords,
        OfftakeSCCounterpartiesContract.columnSchema.contractId, OfftakeSCCounterpartiesContract.columnSchema.schedulingCoordinatorId, injectedSchedulingCoordinatorName
    )

    return (
        <OfftakePanel
            selectedPlantId={props.selectedPlantId}
            offtakeContractRecords={filteredContractRecords}
            offtakeCreditRatingRecords={filteredContractCreditRatingRecords}
            offtakeRecords={offtakeRecords}
            schedulingCoordinatorRecords={schedulingCoordinatorRecords}
            offtakeContractCounterpartyRecords={filteredContractCounterparties}
            offtakeContractSCCounterpartyRecords={filteredContractSCCounterparties}
            validateCreateContractCounterparty={(record, newRecord) => validateCreateContractCounterparty(OfftakeCounterpartiesContract, OfftakeCounterpartiesContract.columnSchema.offtakeId, record, newRecord)}
            validateCreateContractSCCounterparty={(record, newRecord) => validateCreateContractCounterparty(OfftakeSCCounterpartiesContract, OfftakeSCCounterpartiesContract.columnSchema.schedulingCoordinatorId, record, newRecord)}
            {...props}
        />
    )

}

/**
 * Main stateful OfftakePanel component.
 * @param {*} props 
 * @returns 
 */
function OfftakePanel(props) {

    const [
        offtakeContractRecords, prepareContractLog,
        updateContractLog, addToContractLog, deleteFromContractLog, bulkOpOnContractLog, mergeAndResetContractLog
    ] = useChangeLog(props.offtakeContractRecords, OfftakeContract)
    const [
        offtakeCreditRatingRecords, prepareContractCreditRatingLog,
        updateContractCreditRatingLog, addToContractCreditRatingLog, deleteFromContractCreditRatingLog, bulkOpOnContractCreditRatingLog, mergeAndResetContractCreditRatingLog
    ] = useChangeLog(props.offtakeCreditRatingRecords, OfftakeCreditRating)
    const [
        offtakeContractCounterpartiesRecords, prepareCounterpartyLog,
        updateCounterpartyLog, addToCounterpartyLog, deleteFromCounterpartyLog, bulkOpOnCounterpartyLog, mergeAndResetCounterpartyLog
    ] = useChangeLog(props.offtakeContractCounterpartyRecords, OfftakeCounterpartiesContract, props.validateCreateContractCounterparty)
    const [
        offtakeContractSCCounterpartiesRecords, prepareScCounterpartyLog,
        updateSCCounterpartyLog, addToSCCounterpartyLog, deleteFromSCCounterpartyLog, bulkOpOnSCCounterpartyLog, mergeAndResetSCCounterpartyLog
    ] = useChangeLog(props.offtakeContractSCCounterpartyRecords, OfftakeSCCounterpartiesContract, props.validateCreateContractSCCounterparty)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToContractLogProxy = () => {
        let contract = OfftakeContract.buildNewRecord()
        contract[OfftakeContract.columnSchema.contractId] = generateUUID()
        contract[OfftakeContract.columnSchema.plantId] = props.selectedPlantId
        addToContractLog(contract)
    }
    const deleteFromContractLogProxy = (offtakeContract) => {
        // Delete all counterparty records associated with this contract, if any
        let contractId = offtakeContract[OfftakeContract.columnSchema.contractId]
        let creditRatingsInDeletedContract = offtakeCreditRatingRecords.filter(record => record[OfftakeCreditRating.columnSchema.contractId]===contractId)
        let counterpartiesInDeletedContract = offtakeContractCounterpartiesRecords.filter(record => record[OfftakeCounterpartiesContract.columnSchema.contractId]===contractId)
        let scCounterpartiesInDeletedContract = offtakeContractSCCounterpartiesRecords.filter(record => record[OfftakeSCCounterpartiesContract.columnSchema.contractId]===contractId)
        deleteFromContractCreditRatingLog(creditRatingsInDeletedContract)
        deleteFromCounterpartyLog(counterpartiesInDeletedContract)
        deleteFromSCCounterpartyLog(scCounterpartiesInDeletedContract)
        deleteFromContractLog(offtakeContract)
        removeErrors([contractId])
    }

    const offtakeContractComponents = offtakeContractRecords.map(offtakeContract => {
        const contractId = offtakeContract[OfftakeContract.columnSchema.contractId]
        const offtakeCreditRatings = offtakeCreditRatingRecords.filter(x => x[OfftakeCreditRating.columnSchema.contractId]===contractId)
        const offtakeCounterparties = offtakeContractCounterpartiesRecords.filter(x => x[OfftakeCounterpartiesContract.columnSchema.contractId]===contractId)
        const offtakeSCCounterparties = offtakeContractSCCounterpartiesRecords.filter(x => x[OfftakeSCCounterpartiesContract.columnSchema.contractId]===contractId)
        return (
            <OfftakeContractForm
                key={contractId}
                services={props.services}
                offtakeContract={offtakeContract}
                contractId={contractId}
                updateContract={(update) => updateContractLog(offtakeContract, update)}
                deleteFromContractLog={() => deleteFromContractLogProxy(offtakeContract)}

                offtakeCreditRatingRecords={offtakeCreditRatings}
                updateContractCreditRatingLog={updateContractCreditRatingLog}
                addToContractCreditRatingLog={() =>{
                    let creditRating = OfftakeCreditRating.buildNewRecord()
                    creditRating[OfftakeCreditRating.columnSchema.contractId] = contractId
                    addToContractCreditRatingLog(creditRating)
                }}
                deleteFromContractCreditRatingLog={deleteFromContractCreditRatingLog}

                allOfftakeCounterparties={props.offtakeRecords}
                offtakeCounterparties={offtakeCounterparties}
                updateContractCounterparty={updateCounterpartyLog}
                addToCounterpartyLog={() => {
                    let contractCounterparty = OfftakeCounterpartiesContract.buildNewRecord()
                    contractCounterparty[OfftakeCounterpartiesContract.columnSchema.contractId] = contractId
                    addToCounterpartyLog(contractCounterparty)
                }}
                deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                
                allOfftakeSCCounterparties={props.schedulingCoordinatorRecords}
                offtakeSCCounterparties={offtakeSCCounterparties}
                updateContractSCCounterparty={updateSCCounterpartyLog}
                addToSCCounterpartyLog={() => {
                    let contractCounterparty = OfftakeSCCounterpartiesContract.buildNewRecord()
                    contractCounterparty[OfftakeSCCounterpartiesContract.columnSchema.contractId] = contractId
                    addToSCCounterpartyLog(contractCounterparty)
                }}
                deleteFromSCCounterpartyLog={deleteFromSCCounterpartyLog}
                
                errors={errors}
                removeErrors={removeErrors}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Offtake Contract"
            submitUrl='api/precious/table/offtake_contract'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.offtakeContractChangelog,
                        checks: [buildContractNameErrorConfig(OfftakeContract)]
                    }, {
                        changeLog: payload.offtakeCreditRatingChangelog,
                        checks: [{
                            checkColumn: OfftakeCreditRating.columnSchema.agency,
                            checkFunction: "nullCheck",
                            errMessage: "Please select an agency.",
                            path: buildContractDependencyErrorPath(OfftakeCreditRating, OfftakeCreditRating.columnSchema.agency)
                        }, {
                            checkColumn: OfftakeCreditRating.columnSchema.rating,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a rating.",
                            path: buildContractDependencyErrorPath(OfftakeCreditRating, OfftakeCreditRating.columnSchema.rating)
                        }]
                    }, {
                        changeLog: payload.offtakeContractCounterpartyChangelog,
                        checks: [{
                            checkColumn: OfftakeCounterpartiesContract.columnSchema.offtakeId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select an Offtaker.",
                            path: buildContractDependencyErrorPath(OfftakeCounterpartiesContract, OfftakeCounterpartiesContract.columnSchema.offtakeId)
                        }]
                    }, {
                        changeLog: payload.offtakeContractSCCounterpartyChangelog,
                        checks: [{
                            checkColumn: OfftakeSCCounterpartiesContract.columnSchema.schedulingCoordinatorId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a Scheduling Coordinator.",
                            path: buildContractDependencyErrorPath(OfftakeSCCounterpartiesContract, OfftakeSCCounterpartiesContract.columnSchema.schedulingCoordinatorId)
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetContractLog()
                mergeAndResetContractCreditRatingLog()
                mergeAndResetCounterpartyLog()
                mergeAndResetSCCounterpartyLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    offtakeContractChangelog: prepareContractLog(),
                    offtakeCreditRatingChangelog: prepareContractCreditRatingLog(),
                    offtakeContractCounterpartyChangelog: prepareCounterpartyLog(),
                    offtakeContractSCCounterpartyChangelog: prepareScCounterpartyLog(),
                }
            }}
        >
            <div style={{minWidth: "350px"}}>
                {offtakeContractComponents}
                <AddFloaterButtonWithPrompt
                    onClick={addToContractLogProxy}
                    labelContent="Add new contract"
                    height={18}
                    width={18}
                />
            </div>
        </ViewPanel>
    )
}

const OfftakeContractForm = React.memo(function({
    services, offtakeContract, contractId, updateContract, deleteFromContractLog,
    offtakeCreditRatingRecords, updateContractCreditRatingLog, addToContractCreditRatingLog, deleteFromContractCreditRatingLog,
    allOfftakeCounterparties, offtakeCounterparties, updateContractCounterparty, addToCounterpartyLog, deleteFromCounterpartyLog, 
    allOfftakeSCCounterparties, offtakeSCCounterparties, updateContractSCCounterparty, addToSCCounterpartyLog, deleteFromSCCounterpartyLog, 
    errors, removeErrors}) {
    
    return (
        <div className="field-group form-instance" style={{scroll: "auto", marginBottom: "100px"}}>
            <ContractHeader
                contractName={offtakeContract[OfftakeContract.columnSchema.contractName]}
                deleteFromContractLog={deleteFromContractLog}
            />
            <ContractIdentification 
                ContractTable={OfftakeContract}
                contract={offtakeContract}
                contractIdCol={OfftakeContract.columnSchema.contractId}
                contractNameCol={OfftakeContract.columnSchema.contractName}
                contractPlantIdCol={OfftakeContract.columnSchema.plantId}
                onContractNameChange={(x) => updateContract({[OfftakeContract.columnSchema.contractName]: x})}
                errors={errors}
                removeErrors={removeErrors}
            />
            <div className="flow-horizontal" style={{border: "solid green 0px", marginBottom: "20px", flexWrap: "wrap"}} >
                <ContractCounterparties
                    ContractCounterpartyTable={OfftakeCounterpartiesContract}
                    contract={offtakeContract}
                    contractIdCol={OfftakeContract.columnSchema.contractId}
                    contractCounterpartyIdCol={offtakeIdCol}
                    contractCounterpartyNameCol={injectedOfftakeName}
                    contractCounterpartyNotesCol={OfftakeCounterpartiesContract.columnSchema.notes}
                    allCounterpartyRecords={allOfftakeCounterparties}
                    filteredContractCounterpartyRecords={offtakeCounterparties}
                    deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                    addToCounterpartyLog={addToCounterpartyLog}
                    updateContractCounterparty={updateContractCounterparty}
                    counterpartyType={"Offtaker"}
                    counterpartyTypePlural={"Offtakers"}
                    errors={errors}
                    removeErrors={removeErrors}
                />
                <ContractCounterparties
                    ContractCounterpartyTable={OfftakeSCCounterpartiesContract}
                    contract={offtakeContract}
                    contractIdCol={OfftakeContract.columnSchema.contractId}
                    contractCounterpartyIdCol={schedulingCoordinatorIdCol}
                    contractCounterpartyNameCol={injectedSchedulingCoordinatorName}
                    contractCounterpartyNotesCol={OfftakeSCCounterpartiesContract.columnSchema.notes}
                    allCounterpartyRecords={allOfftakeSCCounterparties}
                    filteredContractCounterpartyRecords={offtakeSCCounterparties}
                    deleteFromCounterpartyLog={deleteFromSCCounterpartyLog}
                    addToCounterpartyLog={addToSCCounterpartyLog}
                    updateContractCounterparty={updateContractSCCounterparty}
                    counterpartyType={"Scheduling Coordinator"}
                    counterpartyTypePlural={"Scheduling Coordinators"}
                    errors={errors}
                    removeErrors={removeErrors}
                />
                <div style={{flexGrow: 1, height: "100%"}}>
                    <div className="header">Notes</div>
                    <PalantirDispatchedTextField
                        label="Notes"
                        value={offtakeContract[OfftakeContract.columnSchema.notes]}
                        multiline
                        rows={4}
                        fullWidth
                        variant="filled"
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.notes]: x})}
                    />
                    <div style={{marginTop: "20px"}}>
                        <PalantirTextField
                            label="Sharepoint Folder Link"
                            value={offtakeContract[OfftakeContract.columnSchema.sharepointLink]}
                            helperText="This should be the folder link, not the file."
                            onChange={(x) => updateContract({[OfftakeContract.columnSchema.sharepointLink]: x})}
                        />
                        <div style={{marginTop: "4px"}}><a href={offtakeContract[OfftakeContract.columnSchema.sharepointLink]} target="_blank">Click here to visit Sharepoint link</a></div>
                    </div>
                </div>
            </div>
            <div className="offtake-metada-sections flow-horizontal">
                <div className="offtake-metadata-section flow-vertical" style={{minWidth: "200px", width: "200px", flexGrow: 1}}>
                    <div className="header">Contract Information</div>
                    <PalantirSelector 
                        label="Status"
                        value={offtakeContract[OfftakeContract.columnSchema.status]}
                        items={OfftakeContract.options.status}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.status]: x})}
                    />
                    <PalantirSelector 
                        label="Contract Type"
                        value={offtakeContract[OfftakeContract.columnSchema.contractType]}
                        items={OfftakeContract.options.contractType}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.contractType]: x})}
                        style={{marginRight: "20px"}}
                    />
                    <PalantirDatePicker
                        label="Term Start Date"
                        value={offtakeContract[OfftakeContract.columnSchema.termStartDate]}
                        onChange={(date) => updateContract({[OfftakeContract.columnSchema.termStartDate]: date})}
                        fullWidth
                    />
                    <PalantirDatePicker
                        label="Term End Date"
                        value={offtakeContract[OfftakeContract.columnSchema.termEndDate]}
                        onChange={(date) => updateContract({[OfftakeContract.columnSchema.termEndDate]: date})}
                    />
                    <PalantirTextField
                        label="Term (Years)"
                        value={offtakeContract[OfftakeContract.columnSchema.termYears]}
                        InputProps={{
                            inputComponent: TextMaskTermYears
                        }}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.termYears]: x})}
                        style={{paddingRight: "20px"}}
                        fullWidth
                    />
                    <PalantirDispatchedTextField
                        label="Extension Terms"
                        value={offtakeContract[OfftakeContract.columnSchema.extensionTerms]}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.extensionTerms]: x})}
                        style={{paddingRight: "20px"}}
                        fullWidth
                    />
                    <div style={{display: "inline-flex"}}>
                        <HelpPrompt
                            services={services}
                            style={{position: "relative", top: "20px", marginRight: "4px", fontSize: "small"}}
                            title="Commercial Operation Date - Guaranteed"
                            item={<HelpProjectCOD />}
                        />
                        <PalantirDatePicker
                            label="Guaranteed COD"
                            value={offtakeContract[OfftakeContract.columnSchema.contractualCOD]}
                            onChange={(date) => updateContract({[OfftakeContract.columnSchema.contractualCOD]: date})}
                        />
                    </div>
                    <PalantirDatePicker
                        label="Initial Delivery Date"
                        value={offtakeContract[OfftakeContract.columnSchema.initialDeliveryDate]}
                        onChange={(date) => updateContract({[OfftakeContract.columnSchema.initialDeliveryDate]: date})}
                    />
                    <PalantirDatePicker
                        label="Guaranteed Cliff Date"
                        value={offtakeContract[OfftakeContract.columnSchema.ppaCliffDate]}
                        onChange={(date) => updateContract({[OfftakeContract.columnSchema.ppaCliffDate]: date})}
                    />
                    <PalantirBooleanSelector
                        label="Environmental Attributes"
                        checked={offtakeContract[OfftakeContract.columnSchema.environmentalAttributes]}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.environmentalAttributes]: x})}
                        style={{marginRight: "20px"}}
                    />
                </div>
                <div className="offtake-metadata-section flow-vertical" style={{minWidth: "260px", width: "200px", flexShrink: 1, paddingLeft: "10px"}}>
                    <div className="header">Pricing</div>
                    <PalantirDispatchedTextField
                        label="Price"
                        value={offtakeContract[OfftakeContract.columnSchema.priceDollarpermwh]}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.priceDollarpermwh]: x})}
                        helperText="If entering a static price, just include the dollar amount (omit the dollar sign)."
                    />
                    <PalantirDispatchedTextField
                        label="Pricing Unit"
                        value={offtakeContract[OfftakeContract.columnSchema.pricingUnit]}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.pricingUnit]: x})}
                    />
                    <PalantirDispatchedTextField
                        label="Annual Escalation Rate"
                        value={offtakeContract[OfftakeContract.columnSchema.annualEscalationRate]}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.annualEscalationRate]: x})}
                    />
                    <PalantirDatePicker
                        label="Escalation Date"
                        value={offtakeContract[OfftakeContract.columnSchema.escalationDate]}
                        onChange={(date) => updateContract({[OfftakeContract.columnSchema.escalationDate]: date})}
                    />
                    <PalantirDispatchedTextField
                        label="Escalation Language"
                        value={offtakeContract[OfftakeContract.columnSchema.escalationLanguage]}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.escalationLanguage]: x})}
                        multiline
                        rows={3}
                        fullWidth
                        variant="filled"
                        style={{paddingRight: "20px"}}
                    />
                    <PalantirBooleanSelector
                        label="TOD Factors"
                        checked={offtakeContract[OfftakeContract.columnSchema.todFactors]}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.todFactors]: x})}
                    />
                    <PalantirBooleanSelector
                        label="Credit Support Requirement"
                        checked={offtakeContract[OfftakeContract.columnSchema.creditSupportRequirement]}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.creditSupportRequirement]: x})}
                        style={{marginRight: "20px"}}
                    />
                </div>
                <div className="offtake-metadata-section flow-vertical" style={{minWidth: "280px", width: "280px", flexShrink: 1, flexGrow: 1}}>
                    <div className="header">Physical Limitations</div>
                    <PalantirTextField
                        label="Capacity Limit (MW)"
                        value={offtakeContract[OfftakeContract.columnSchema.capacityLimitMw]}
                        InputProps={{
                            inputComponent: TextMaskCapacityLimit
                        }}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.capacityLimitMw]: x})}
                    />
                    <PalantirTextField
                        label="Annual Curtailment Allowance (MWh)"
                        value={offtakeContract[OfftakeContract.columnSchema.annualCurtailmentAllowanceMwh]}
                        InputProps={{
                            inputComponent: TextMaskTermYears
                        }}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.annualCurtailmentAllowanceMwh]: x})}
                        style={{minWidth: "280px"}}
                    />
                    <PalantirTextField
                        label="Production Guarantee (MWh)"
                        value={offtakeContract[OfftakeContract.columnSchema.productionGuarantee]}
                        InputProps={{
                            inputComponent: TextMaskTermYears
                        }}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.productionGuarantee]: x})}
                        style={{minWidth: "220px"}}
                    />
                </div>
                <div className="offtake-metadata-section flow-vertical" style={{width: "270px", flexShrink: 1, flexGrow: 1}}>
                    <div className="header">Credit</div>
                    <PalantirSelector 
                        label="Offtaker Market"
                        value={offtakeContract[OfftakeContract.columnSchema.offtakeMarket]}
                        items={OfftakeContract.options.offtakeMarket}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.offtakeMarket]: x})}
                        style={{marginRight: "20px"}}
                    />
                    <PalantirSelector 
                        label="Investment Grade"
                        value={offtakeContract[OfftakeContract.columnSchema.investmentGrade]}
                        items={OfftakeContract.options.investmentGrade}
                        onChange={(x) => updateContract({[OfftakeContract.columnSchema.investmentGrade]: x})}
                        style={{marginRight: "20px"}}
                    />
                    <OfftakeCreditRatingComp
                        contractId={contractId}
                        offtakeCreditRatingRecords={offtakeCreditRatingRecords}
                        updateContractCreditRatingLog={updateContractCreditRatingLog}
                        addToContractCreditRatingLog={addToContractCreditRatingLog}
                        deleteFromContractCreditRatingLog={deleteFromContractCreditRatingLog}
                        errors={errors}
                        removeErrors={removeErrors}
                    />
                </div>
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.offtakeContract, nextProps.offtakeContract) &&
        _.isEqual(prevProps.offtakeCreditRatingRecords, nextProps.offtakeCreditRatingRecords) &&
        _.isEqual(prevProps.offtakeCounterparties, nextProps.offtakeCounterparties) &&
        _.isEqual(prevProps.offtakeSCCounterparties, nextProps.offtakeSCCounterparties) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})

const OfftakeCreditRatingComp = ({contractId, offtakeCreditRatingRecords, updateContractCreditRatingLog, addToContractCreditRatingLog, deleteFromContractCreditRatingLog, errors, removeErrors}) => {
    const components = offtakeCreditRatingRecords.map(creditRatingRecord => {
        const agency = creditRatingRecord[OfftakeCreditRating.columnSchema.agency]
        const rating = creditRatingRecord[OfftakeCreditRating.columnSchema.rating]
        const injectedChangeLogId = creditRatingRecord[injectedChangeLogIdCol]

        const creditRatingErrorPath = [contractId, OfftakeCreditRating.buildId(), injectedChangeLogId]
        const agencyErrorPath = creditRatingErrorPath.concat(OfftakeCreditRating.columnSchema.agency)
        const ratingErrorPath = creditRatingErrorPath.concat(OfftakeCreditRating.columnSchema.rating)

        const agencyEmptyError = errors.get(...agencyErrorPath)
        const ratingEmptyError = errors.get(...ratingErrorPath)

        return (
            <div className="flow-horizontal form-sub-instance" style={{paddingBottom: "5px", marginRight: "20px"}}>
                <PalantirSelector
                    label="Agency"
                    value={agency}
                    helperText={agencyEmptyError ? agencyEmptyError.getMessage() : null}
                    items={OfftakeCreditRating.options.agencies}
                    onChange={(x) => {
                        removeErrors(creditRatingErrorPath)
                        updateContractCreditRatingLog(creditRatingRecord, {[OfftakeCreditRating.columnSchema.agency]: x, [OfftakeCreditRating.columnSchema.rating]: null})
                    }}
                    style={{minWidth: "100px", maxWidth: "100px", marginRight: "20px"}}
                    error={agencyEmptyError}
                />
                <PalantirSelector
                    label="Credit Rating"
                    value={rating}
                    helperText={ratingEmptyError ? ratingEmptyError.getMessage() : null}
                    items={OfftakeCreditRating.options.ratings[agency] || []}
                    onChange={(x) => {
                        removeErrors(ratingErrorPath)
                        updateContractCreditRatingLog(creditRatingRecord, {[OfftakeCreditRating.columnSchema.rating]: x})
                    }}
                    style={{minWidth: "80px", maxWidth: "80px", marginRight: "20px"}}
                    error={ratingEmptyError}
                />
                <DeleteFloaterButtonWithPrompt
                    onClick={() => {
                        removeErrors(creditRatingErrorPath)
                        deleteFromContractCreditRatingLog(creditRatingRecord)
                    }}
                    style={{float: "right"}}
                    height={20}
                    width={20}
                />
            </div>
        )
    })
    return (
        <div style={{minWidth: "280px"}}>
            <div style={{display: "flex", flexWrap: "wrap"}}>{components}</div>
            <AddFloaterButtonWithPrompt
                onClick={addToContractCreditRatingLog}
                labelContent={"Add new credit rating"}
                height={14}
                width={14}
                labelStyle={{fontSize: "10px"}}
            />
        </div>
    )
}


